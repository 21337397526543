@tailwind base;

@tailwind components;

@tailwind utilities;

@layer base {
   select {
      [dir='rtl'] & {
         background-position: left 0.5rem center;
      }
   }
}
