.btn {
   @apply focus:outline-none px-4 py-2 rounded-xl disabled:cursor-not-allowed;
   &.primary {
      @apply bg-primary text-white border border-transparent ring-offset-1 ring-transparent;
      @apply focus:ring-1 focus:ring-primary;
      &:not(:disabled) {
         @apply hover:ring-1 hover:ring-primary hover:ring-offset-1 border-primary;
      }
      &:disabled,
      &.disabled {
         @apply bg-gray-100 hover:ring-0 hover:ring-offset-0 border-gray-100 text-gray-400;
      }
   }

   &.secondary {
      @apply bg-gray-200 text-[#212529] border border-transparent ring-offset-1 ring-transparent;
      @apply focus:ring-1 focus:ring-gray-200;
      &:not(:disabled) {
         @apply hover:ring-1 hover:ring-gray-200 hover:ring-offset-1 border-gray-200;
      }
      &:disabled,
      &.disabled {
         @apply bg-gray-100 focus:ring-0 hover:ring-0 hover:ring-offset-0 border-gray-100 text-gray-400;
      }
   }
}

.input {
   &::placeholder {
      @apply text-gray-400;
   }
   @apply border border-gray-200 w-full rounded-default px-3 focus:outline-none focus:border-primary hover:border-primary;
   &:not([type='textarea']) {
      @apply h-[52px];
   }
   &:focus {
      box-shadow: 0 0 0 0.14rem rgba(var(--primary), 0.2);
   }
   &.file {
      @apply ltr:pr-12 rtl:pl-12;
      background-image: url('/icons/file-arrow.svg');
      background-repeat: no-repeat;
      background-size: calc(1em + 0.375rem) calc(1em + 0.375rem);
      [dir='ltr'] & {
         background-position: right calc(1em + 0.1875rem) center;
      }

      [dir='rtl'] & {
         background-position: left calc(1em + 0.1875rem) center;
      }
   }

   &__append {
      @apply ltr:rounded-r-default rtl:rounded-l-default ltr:border-l rtl:border-r min-w-[32px];
      @apply border-gray-200 border h-auto flex items-center justify-center ltr:border-l-0 rtl:border-r-0 flex-shrink-0;
   }
   &__prepend {
      @apply ltr:rounded-l-default rtl:rounded-r-default ltr:border-r rtl:border-l min-w-[32px];
      @apply border-gray-200 border h-auto flex items-center justify-center ltr:border-r-0 rtl:border-l-0 flex-shrink-0;
   }

   &.appended {
      @apply ltr:rounded-r-none rtl:rounded-l-none;
   }
   &.prepended {
      @apply ltr:rounded-l-none rtl:rounded-r-none;
   }
   &:disabled,
   &.disabled {
      @apply bg-gray-100 cursor-not-allowed focus:ring-0 hover:ring-0 hover:ring-offset-0 border-gray-100 text-gray-400;
   }

   &__error {
      @apply !border-red-500;
      &:focus,
      &:hover {
         box-shadow: 0 0 0 0.14rem rgba(239, 68, 68, 0.2);
      }
   }
   &__valid {
      @apply !border-green-500;
      &:focus,
      &:hover {
         box-shadow: 0 0 0 0.14rem rgba(16, 185, 129, 0.2);
      }
   }
}

.react-select input:focus {
   box-shadow: none !important;
}

.dd {
   &:focus {
      box-shadow: 0 0 0 0.25rem rgba(var(--primary), 0.2);
   }
}
div .react-datepicker__header {
   @apply bg-white border-b-0 !rounded-tr-default !rounded-tl-default;
}
div .react-datepicker {
   @apply rounded-default border-0;
   box-shadow: 0px 14px 44px -1px rgba(0, 0, 0, 0.11);
   &__day-name,
   &__day {
      @apply !w-10 h-10 leading-[2.5rem];
   }
   &__day {
      @apply text-[15px] focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-primary hover:ring-1 hover:ring-primary hover:ring-offset-1 rounded-full hover:rounded-full;

      &--disabled {
         @apply cursor-not-allowed hover:!ring-transparent;
      }
   }

   &__day--selected,
   &__day--in-selecting-range,
   &__day--in-range,
   &__month-text--selected,
   &__month-text--in-selecting-range,
   &__month-text--in-range,
   &__quarter-text--selected,
   &__quarter-text--in-selecting-range,
   &__quarter-text--in-range,
   &__year-text--selected,
   &__year-text--in-selecting-range,
   &__year-text--in-range,
   &__day--keyboard-selected,
   &__month-text--keyboard-selected,
   &__quarter-text--keyboard-selected,
   &__year-text--keyboard-selected {
      @apply !bg-primary;
   }

   &__day--outside-month {
      @apply !bg-transparent;
      color: transparent !important;
      pointer-events: none;
   }
}
.react-datepicker-wrapper .input {
   @apply ltr:pr-12 rtl:pl-12;
   background-image: url('/icons/calendar.svg');
   background-repeat: no-repeat;
   background-size: calc(1em + 0.375rem) calc(1em + 0.375rem);
   [dir='ltr'] & {
      background-position: right calc(1em + 0.1875rem) center;
   }

   [dir='rtl'] & {
      background-position: left calc(1em + 0.1875rem) center;
   }
}

.fc-daygrid-day-events {
   @apply px-2 mb-2;
}
