.btn {
   @apply inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md;
   &:focus {
      @apply outline-none;
   }
}

.btn-primary {
   @apply bg-indigo-600 text-white;
   &:hover {
      @apply bg-indigo-500;
   }
   &:active {
      @apply bg-indigo-700;
   }
}
.btn-danger {
   @apply bg-red-600 text-white;
   &:hover {
      @apply bg-red-500;
   }
   &:active {
      @apply bg-red-700;
   }
}
.fc-toolbar-chunk {
   @apply flex items-center !min-w-max;
}
.fc-toolbar-title {
   @apply !text-base;
}
.fc-daygrid-day-top {
   @apply !flex-row;
}
.fc-header-toolbar.fc-toolbar {
   @apply bg-gray-100 p-2 rounded flex sm:flex-row flex-col;
}
.fc-daygrid-day-number {
   @apply static;
}
.fc .fc-daygrid-day-number {
   @apply w-full;
}
.fc-daygrid-day-frame:hover {
   button {
      @apply opacity-100;
   }
}
.fc .fc-daygrid-day.fc-day-today {
   @apply !bg-white;
}
.fc-today-button {
   @apply !bg-gray-200 !text-black !capitalize !border-0 !opacity-100;
}
.fc-prev-button {
   @apply !bg-gray-200 !text-gray-700 !border-0 !opacity-100;
}
.fc-next-button {
   @apply !bg-gray-200 !text-gray-700 !border-0 !opacity-100;
}
.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-dayGridDay-button,
.fc-timeGridDay-button {
   @apply !bg-gray-200 !text-black !capitalize !border-0 !opacity-100 !ring-0;
}

.fc-timegrid-slot-minor {
   @apply !border-0;
}
.fc-day-today {
   @apply !bg-white;
}
.fc-col-header-cell {
   @apply text-xs lg:text-sm md:whitespace-nowrap;
}
.fc-button-active {
   @apply !font-bold !bg-gray-300;
}

.custom-scrollbar::-webkit-scrollbar {
   -webkit-appearance: none;
   width: 7px;
   height: 10px !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
   border-radius: 5px;
   background-color: rgba(0, 0, 0, 0.178);
   height: 10px !important;
   -webkit-box-shadow: 0 0 2px rgba(255, 255, 255, 0.698);
}

.otp-input {
   input {
      @apply h-full rounded-default border border-gray-300;
   }
}

.required:after {
   content: ' *';
   color: red;
   padding-left: 3px;
}

/* input[type=text],input[type=number],textarea {
   background-color: #f9f9f9 !important;
}  */
